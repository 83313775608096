@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(-10deg);
  }
  12% {
    transform: rotate(10deg);
  }
  18% {
    transform: rotate(-10deg);
  }
  24% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wiggle-hover:hover {
  animation: wiggle 1.3s ease-in-out forwards;
  animation-iteration-count: 1;
}
