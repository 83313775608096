@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@media only screen and (min-width: 900px) and (max-width: 934px) {
  /* Styles here will apply only when viewport width is between 900px and 934px, inclusive */
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

svg {
  pointer-events: none;
}

@keyframes slideFadeIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes driftUpFadeOutGrow {
  0% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(1.5) translateY(-20px) translateX(10px);
  }
}

.animate-on-scroll {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-name: slideFadeIn;
}
